<template>
  <div>
    <div v-if="!pageLoaded" style="padding-top:130px; height: 280px;">
      <div class="loader" style="text-align: center;"></div>
    </div>
    <div v-else style="padding-top: 56px">
      <div :class="'mobileNavBar ' + deviceType">
        <div class="appBar-header">
          <div @click="routeBack('/',false)" class="header-route-back">
        <span v-if="deviceType === 'ios'">
          <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
            <span v-else>
          <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
          </div>
          <div class="header-title-container">
            <p class="header-title">
              Destek Merkezi
            </p>
          </div>
          <div class="tickets" @click="">
            <router-link to="/tickets" style="text-decoration: none">
              <img src="https://gcdn.bionluk.com/site/icon/ic_add.svg" alt="">
            </router-link>
          </div>
        </div>
      </div>

      <div class="support-detail">
        <div v-if="pageLoaded" style="display: flex;flex-direction: column">
          <div class="support-category-content">
            <span class="support-category-content-header">
              {{ article.title }}
            </span>
            <template v-if="article.asset">
              <video v-if="article.asset.type === 'video'" class="mt-1 mb-2" @play="sendPlayEvent(article.asset.id)" width="100%" :poster="article.asset.info.thumbnailUrl" controls>
                <source :src="article.asset.info.videoUrl" type="video/mp4">
                Tarayacınız video oynatımını desteklemiyor.
              </video>
              <img v-else :src="article.asset.info.imageUrl" width="100%" />
            </template>
            <div v-html="article.text" id="html-text" class="support-category-content-text" />
          </div>
        </div>
        <div v-else>
          <div class="loader" style="text-align: center"></div>
        </div>
      </div>

      <Faqs is-mobile-faq="false" style="margin-left: 20px; margin-right: 20px; margin-top: -50px; margin-bottom: 30px" v-if="faqData.accordionData && faqData.accordionData.length > 0" :contentData="faqData" :calledFrom="'article'"
             :cat-id="category.parent_id ? null : category.id"
             :sub-cat-id="category.parent_id ? category.id : null"
             :impression-u-u-i-d="impressionUUID"
             :article-id="this.article.id"
      />

      <div v-if="showYeterlimi" class="yeterlimi">
        <div class="support-icon-container" style="margin-top: 20px;">
          <img
            src="https://gcdn.bionluk.com/site/cicons/ic-thumb.svg"
            onload="SVGInject(this)"
            class="support-icon"
          />
        </div>
        <p class="y-text">Bu içerik faydalı oldu mu?</p>
        <button @click="rateArticle('positive')" class="y-button">Evet, yeterli.</button>
        <button @click="rateArticle('negative')" class="y-button">Hayır, hala yardıma ihtiyacım var.</button>
      </div>
    </div>

  </div>
</template>

<script>

import Faqs from "@/pages/components/Faqs";

  export default {
    name: "src-pages-body-staticContents-support-categoryList-v1",
    components:{Faqs},
    data() {
      return {
        impressionUUID:null,
        showYeterlimi:true,
        comingPath:null,
        slug:null,
        isVideoEventSend: false,
        pageLoaded:false,
        category:{
          id:null,
          parent_id:null,
          sub_categories:[],
          all_sub_categories:[],
        },
        article:{video:{id:null}},
        other_support_articles:[],


        faqData: {
          title: " hakkında merak edilen sorular?",
          text: "En çok sorduğun konuları bir araya topladık. 😇",
          accordionData: []
        },
      };
    },
    methods: {
      sendPlayEvent(id){
        if(!this.isVideoEventSend){
          this.Helper.trackEvents.customEvent("custom_video_view", {
            title:this.article.title,
            id,
            impression_id:this.impressionUUID,
            page:this.$store.state.componentMap.page
          });
          this.isVideoEventSend = true;
        }
      },
      rateArticle(score){
        this.Helper.trackEvents.customEvent("rate_support_article", {title:this.article.title,type:score, article_id:this.article.id, impression_id:this.impressionUUID});
        if(score==='negative'){

          this.$router.push('/tickets?tab=new');
        } else {
          this.$toasted.global.infoToast({
            description: 'Teşekkürler 🥰'
          });
          this.showYeterlimi = false;
        }

      },
      getTopics(){
        this.impressionUUID = this.Helper.generateUUID()
        this.pageLoaded = false;
        this.api.content
          .retrieveSupportTopicDetailsv2(this.slug, this.comingPath)
          .then(({ data }) => {
            let result = data;
            if (result.success) {

              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

              this.category = result.data.category;
              this.other_support_articles = result.data.other_support_articles;
              this.article = result.data.article;
              this.faqData.accordionData = result.data.faqs;
              this.faqData.title = this.article.title + ' hakkında merak edilen sorular?';
              this.pageLoaded = true;

              this.Helper.trackEvents.pageView({title:result.data.article.title, categoryId:result.data.category.id, article_id:result.data.article.id, impression_id:this.impressionUUID});

              this.$nextTick(function () {
                const htmlElem = document.getElementById('html-text')
                if (htmlElem) {
                  let elems = htmlElem.getElementsByTagName('img')
                  if (elems && elems.length) {
                    for (let i = 0; i < elems.length; i++) {
                      elems[0].style.width = '100%';
                    }
                  }
                }
              })
            } else {
              this.$router.push(result.data.redirect_url);
            }

          })
          .catch(err => {
            this.pageLoaded = true;
            this.$toasted.global.errorToast({
              description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
            });
          });
      }
    },
    computed:{
      navBarData: function () {

        if(this.category.parent_title){
          return {
            navBarList: [
              {
                title: "Destek",
                path: "/destek"
              },
              {
                title: this.category.parent_title,
                path: "/destek/kategori/"+this.category.parent_slug
              },
              {
                title: this.category.title,
                path: "/destek/kategori/"+this.category.slug
              },
              {
                title: this.article.title,
                path: "/destek/kategori/"+this.category.slug
              }
            ],
            buttonTitle: "Yardım Merkezine Dön",
            buttonPath: "/destek"
          }
        } else {
          return {
            navBarList: [
              {
                title: "Destek",
                path: "/destek"
              },
              {
                title: this.category.title,
                path: "/destek/kategori/"+this.category.slug
              },
              {
                title: this.article.title,
                path: "/destek/kategori/"+this.category.slug
              }
            ],
            buttonTitle: "Yardım Merkezine Dön",
            buttonPath: "/destek"
          }
        }

      }
    },
    watch:{
      '$store.state.route.path': function (newValue, oldValue) {
        if(newValue && newValue !== oldValue){
          document.body.scrollTop = 0;
          this.pageLoaded = false;
          let pathArr = this.$store.state.route.fullPath.split('/');
          if(pathArr && pathArr[2]){
            this.slug =  pathArr[2];
            this.getTopics();
            this.isVideoEventSend = false;
          }
        }
      }
    },
    created() {

      if(!this.comingPath){
        this.comingPath = this.$store.state.route.from.path;
      }

      let pathArr = this.$store.state.route.fullPath.split('/');
      if(pathArr && pathArr[2]){
        this.slug =  pathArr[2];
        this.getTopics();
      } else {
        this.$router.push('/destek')
      }

    }
  };
</script>

<style scoped lang="scss">
  .support-detail-header{
    display: flex;
    align-items: center;
    *{
      padding: 2px;
      text-align: center;
    }
  }


  .support-detail{
    padding: 10px 15px 50px 15px;
  }

  .support-category-content-header{
    font-size: 22px;
    font-weight: 600;
    color: #2d3640;
    display: flex;
    padding: 10px 0;
  }



  .bread {
    font-size: 14px;
    color: #2d3640;
  }

  .bread span {
    color: #b2bcc8;
    text-decoration: none !important;;
  }


  .yeterlimi{
    margin: 10px auto 50px;
    width: 88%;
    height: 290px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 #2d36400c;
    border: solid 1px #eaedf2;
    background-color: #00a575;
    display: flex;
    align-items: center;


    flex-direction: column;

    .y-text{
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.75;
      letter-spacing: normal;
      color: #ffffff;
    }

    .y-button{
      margin-bottom: 20px;
      padding-left: 25px;
      padding-right: 25px;
      height: 44px;
      border-radius: 2px;
      border: solid 1px #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #00a575;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      color: #ffffff;
      &:hover{
        background: #00a575!important;
        color: #ffffff;
        opacity: 0.8;
      }
    }

  }

  .support-icon-container {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .support-icon /deep/{
    width: 24px;
    height: 24px;
    path {
      fill: #00a575;
    }
  }
</style>
